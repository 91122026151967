@keyframes react-loading-skeleton {
    100% {
        transform: translateX(100%);
    }
}

.react-loading-skeleton {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block;
    border-radius: 0.25rem;
    display: block;
    line-height: 1;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: #ebebeb;
}

.react-loading-skeleton::after {
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg,
            var(--base-color),
            var(--highlight-color),
            var(--base-color));
    transform: translateX(-100%);

    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}



.container {
    max-width: 1160px;
    margin: 0 auto;
}

.containersearch {
    max-width: 1280px;
    margin: 0 auto;
}

.margin_top_head_search {
    margin-top: 69px;
}

.margin_top_head {
    margin-top: 113px;
}

.d_flex {
    display: flex;
}

.d_flex_between {
    display: flex;
    justify-content: space-between;
}

.hotel-info-review-heading_banner {
    display: flex;
    justify-content: space-between;
    background-color: #EAF8FF;
    padding: 24px 20px;
    margin-bottom: 20px;
}

.hotel-info-review-heading_content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.hotel-info-review-heading_content_button {
    background-color: #021A94;
}

.header-loyalty_banner {
    padding: 10px;
    background-color: #EAF8FF;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.header-loyalty_banner_flex {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.header-loyalty_banner_flex_column {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.header-loyalty_banner_button span {
    background-color: #021A94;

}

.radius {
    border-radius: 10px !important;
}

.left_search {
    width: 303px;
    margin-right: 20px;
    border: 1px solid rgba(0, 0, 0, .04);
    border-radius: 12px;
}

.right_search {
    width: 935px;
}

.bottonbottom {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 50%;
}

.radius-new {
    border-radius: 24px !important;
}

.radius-TL {
    border-radius: 10px 10px 0 0 !important;
}

.radius3 {
    border-radius: 3px !important;
}

.radius12 {
    border-radius: 12px !important;
}

.main-input-going {
    display: flex;
    justify-content: space-between;
    padding: 24px 54px;
}

.input-autocomplte-srp {
    width: 100%;
    border: 1px solid #ebebec;
    border-radius: 3px;
    padding: 14px 16px;
}

.input-autocomplte-srp-button {
    width: 206px;
    border: 1px solid #ebebec;
    border-radius: 3px;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* hdp */

.ig-hotel-details-flex-container {
    display: flex;
    gap: 16px;
    width: 100%;
    box-sizing: border-box;
    margin: 29px auto 0;
    padding: 0 25px;
}

.ig-hotel-details-sub-container {
    max-width: 920px;
    width: 100%;
}

.ig-hotel-details-pricing-main-container {
    max-width: 294px;
    width: 100%;
    border: 1px solid #f4f4f4;
    border-radius: 8px;
}

.ig-hotail-details-classic-room-container {
    width: 100%;
    position: sticky;
    top: 69px;
    padding: 0 24px;
}

.ig-hotail-details-feature-amenities-info-container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 24px;
}

.ig-hotel-details-select-your-room-tab-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
}

.ig-hotel-details-room-card-main-container-popup {
    padding: 20px;
    width: 274px;
    border-radius: 8px;
    border: none;

}

.ig-hotel-details-container-wrapper {
    max-width: 1280px;
    margin: 0 auto;
}

.ig-hotel-details-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 32px auto 23px auto;
    align-items: center;
    padding: 0 25px;
    box-sizing: border-box;
}

.ig-hotail-details-title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}

.mb9 {
    margin-bottom: 9px;
}

.mb5 {
    margin-bottom: 5px;
}

.d_flex_carausel {
    display: flex;
    gap: 8px;
    padding: 0px 25px;
}

.ig-hotail-details-bottom-bar-container {
    display: flex;
    padding: 28px 25px 0px;
    gap: 15px;
}

.mb25 {
    margin-bottom: 25px;
}

.mb24 {
    margin-bottom: 24px;
}

.mb16 {
    margin-bottom: 16px;
}

.mb12 {
    margin-bottom: 16px;
}

.ig-hotel-details-amenities {
    padding: 32px 24px 24px;
    border: 1px solid rgb(244, 244, 244);
    border-radius: 8px;
}

.ig-hotel-details-about-property-main-container {
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    display: flex;
    padding: 48px 48px 0 48px;
    box-sizing: border-box;
    margin-top: 48px;
    gap: 21px
}

.ig-hotel-details-about-property-heading {
    max-width: 342px;
    width: 100%;
}

.ig-hotel-details-about-property-content {
    width: 100%;

}

.w100 {
    width: 100%;
}

.mb4 {
    margin-bottom: 4px;
}

.mb8 {
    margin-bottom: 8px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb16mt20 {
    margin-bottom: 16px;
    margin-top: 20px;
}

.ig-hotel-details-property-policy-main-container {
    margin-top: 16px;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    display: flex;
    padding: 48px;
    gap: 21px
}

.mt40 {
    margin-top: 40px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb37 {
    margin-bottom: 37px;
}

.mb30 {
    margin-bottom: 30px;
}

.ig-hotail-details-classic-room-only-tag {
    display: flex;
    padding-bottom: 10px;
    gap: 8px;
    align-items: center;
}

.ig-hotail-details-classic-room-only-tag59 {
    padding-bottom: 16px;
    gap: 8px;
    padding-top: 59px;
    align-items: center;
    display: flex;
}

.ig-hotail-details-rating-left {
    display: flex;
    gap: 21px;
    margin-bottom: 16px;
}

.padding24_gap15 {
    padding: 24px 0;
    gap: 15px;
}

.padding24_16 {
    padding: 24px 0 16px;
}

.paddingBottom26_gap30 {
    padding-bottom: 26px;
    gap: 30px;
}

.paddingBottom18 {
    padding-bottom: 18px;
}

.paddingBottom18_gap8 {
    padding-bottom: 18px;
    gap: 8px;
    align-items: center;
}

.flex_wrap_gap15 {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.gap8_alignCenter_width48 {
    gap: 8px;
    align-items: center;
    width: 48%;
}

/* mobile */
.mobile_hdp_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #0000001a;
    background: #FFF;
    gap: 10px;
    height: 23px;
}

.HotelDetailsPageMobile_ig-hdp-room-container {
    display: flex;
    gap: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 12px 20px 10px;
}

.HotelDetailsPageMobile_ig-hdp-review-sub-rating {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 20px;
}

.HotelDetailsPageMobile_ig-hdp-bottom-bar-container {
    display: flex;
    background: #fff;
    box-shadow: 2px 0 40px rgba(0, 0, 0, .07);
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    position: fixed;
}

.ig_card_main {
    padding: 32px 24px 36px;
    margin-top: 24px;
    border: 1px solid rgb(244, 244, 244);
    border-radius: 8px;
}

.ig-hotel-details-similarprop {
    padding: 62px 25px 48px 25px
}

.recommendation-card-main-conatiner {
    border-radius: 12px;
    padding: 15.45px 16.68px 19px 14.86px;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.07);
    height: auto;
    margin: 0 8px;

}

.ig-hotel-details-similarprop-card {
    padding: 62px 25px 48px 25px
}

.ig-hotel-details-similarprop-card-flex {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 0 0;
}

.mb15mt24 {
    margin-top: 24px;
    margin-bottom: 15px;
}

.dflex_center_gap15_pad15_16 {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px 0 15px 16px;
}

.flex_center_gap5 {
    display: flex;
    gap: 5px;
    align-items: center;
}

.separator {
    width: 1px;
    background: #eee;
    height: 26px;
}

.tile-view-card-container {
    margin: 32px 16px 16px;
    background: #fff;
    box-shadow: 0 2px 40px rgba(0, 0, 0, .07);
    border-radius: 12px;
}

.data-section-inner {
    padding: 12px 16px 16px;
}

.review-rating-mob {}

.review-rating-sub-container-mob {
    display: flex;
    margin-bottom: 2px;
    gap: 2px;
}

.room-price-starting-text {
    border-top: 1px solid #F0F1F1;
    margin: 12px 0 5px 0;
    padding-top: 12px;
}

.d_flex_filter_srp {
    display: flex;
    gap: 15px;
    padding: 11px 0 10px 16px;
    align-items: center;
}

.upper-filters-skeleton {
    overflow-y: hidden;
    background: #fff;
    position: sticky;
    top: 57px;
    z-index: 99;
    display: flex;
}

.property-count {
    padding: 25px 16px 0;
}

.amenities-mob {
    display: flex;
    gap: 5px;
    padding: 12px 16px;
    align-items: center;

}

.filtertag_container {
    border: 1px solid #e0e1e3;
    border-radius: 4px;
    padding: 6px 12px 6px 8px;
    background-color: #fff;
    margin: 0 4px 0 0;
    height: 32px;
}



/* SRP Desktop */
.p16 {
    padding: 16px;
    border-bottom: 1px solid #d1d3d4;
    border-top: 1px solid #d1d3d4;
    margin-bottom: 16px;

}

.quick-filter-checkbox-unchecked {
    padding: 0 16px 16px;
    gap: 15px;
}

.filter-img {
    padding: 16px 16px 32px;
}

.filter-widget-search-section {
    padding: 12px 16px 12px 41px;
    margin: 16px 16px 16px 16px;
    border: 1px solid #d1d3d4;
    display: flex;
    gap: 10px;
}

.filterWidget_price_range_section {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.price_range_progress_bar {
    display: flex;
    padding: 23px 16px 0;
    margin-bottom: 16px;
    align-items: center;
}

.price_range_progress_bar_arrow {
    width: 100%;
}

.price_range_progress_price {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d1d3d4;
    padding: 0 16px 16px;
    margin-bottom: 16px;
}

.g10 {
    display: flex;
    gap: 10px
}

.d_flex_between_16 {
    display: flex;
    justify-content: space-between;
    padding: 0 16px 16px;
}

.align_center {
    align-items: center;
}

.prop-found-main {
    padding-top: 6px;
    padding-bottom: 6px;
    margin: 0 0 0 10px;

}

.prop-ratings-inner {
    display: flex;
    gap: 12px;
    margin: 10px 5px 0 10px;
}

.filter {
    border-radius: 24px;
    border: 1px solid #E0E1E3;
    background: #FFF;
    padding: 8px 16px;
    display: flex;
    align-items: center;

}

.corosal-slide {
    max-width: 946px;
    margin-right: 10px;
    box-sizing: border-box;
    padding: 16px 20px 16px 15px;
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, .04);
    ;
    gap: 20px;
}

.inner_carousel_block {
    max-width: 223px;
    width: 24.57%;
}

.inner_text_block {
    width: 75%;
}

.thumb-top-heading-row {}

.innerreviewrow {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 15px;
}

.room-and-amenities-section {
    display: flex;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 8px;
    padding: 16px 24px 16px 16px;
    min-height: 155px;
    height: auto;
    max-width: 643px;
}

.inner-review-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.inner-title-section_header {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: 5px;
}


.inner-type-section-container {
    display: flex;
    gap: 5px;
    padding-bottom: 5px;
}

.inner-details-section {
    display: flex;
    gap: 2px;
}


.review-rating {
    text-align: right;
    width: 40%;
}

.review-rating-sub-container {
    display: flex;
    align-items: flex-end;
    justify-content: right;
    margin-bottom: 2px;
    gap: 2px
}


.review-rating-inner {
    display: flex;
    align-items: flex-end;
    justify-content: right;
    padding-bottom: 5px;
}

.price-row {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 50%;
    max-width: 246px;
    width: 35%;
}


.room-and-amenities-section-inner {
    max-width: 415px;
    width: 62.5%;
}

.room-and-amenities-section-inner div {
    padding-bottom: 5px;
}



/*----------Madan home page skeleton start-------*/

.w_100per {
    width: 100%;
}

.max_1160_m0 {
    max-width: 1160px;
    margin: 0 auto;
}

.max_1160_mt20 {
    max-width: 1160px;
    margin: 20px auto 0;
}

.max_1160_mt25 {
    max-width: 1160px;
    margin: 25px auto 0;
}

.max_1160 {
    max-width: 1160px;
    margin: 0 auto;
    gap: 10px;
}

.max_1160_with_mt-40 {
    max-width: 1160px;
    margin: -40px auto 16px;
    border-radius: 10px;
    background: #fff;
    position: relative;
    z-index: 1;
}

.max_1160_with_mt60 {
    max-width: 1160px;
    margin: 60px auto 16px;
}

.max_1160_with_mt48 {
    max-width: 1160px;
    margin: 48px auto 16px;
}

.mt65_mb33 {
    margin-top: 65px;
    margin-bottom: 33px;
}

.df_jcc {
    display: flex;
    justify-content: center;
}

.gap20 {
    gap: 20px;
}

.mb12 {
    margin-bottom: 12px;
}

.mb46 {
    margin-bottom: 46px;
}

.mb47 {
    margin-bottom: 47px;
}

.mb48 {
    margin-bottom: 48px;
}

.mb79 {
    margin-bottom: 79px;
}

.mb107 {
    margin-bottom: 107px;
}

.pt10_w100per {
    padding-top: 10px;
    width: 100%;
}

.pt20 {
    padding-top: 20px;
}

.pt30 {
    padding-top: 30px;
}

.SearchWidgetSkeleton_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    padding: 26px;
    border: 1px solid #ebebeb;
    border-radius: 10px;
}

.SearchWidgetSkeleton_box_input {
    flex: auto;
}

.SearchWidgetSkeleton_box_label {
    margin-bottom: 15px;
}

.SearchWidgetSkeleton_button {}

@media only screen and (min-width:1024px) and (max-width:1279px) {

    .max_1160_m0 {
        max-width: 980px;
    }

    .max_1160_mt20 {
        max-width: 980px;
    }

    .max_1160_mt25 {
        max-width: 980px;
    }

    .max_1160 {
        max-width: 980px;
    }

    .max_1160_with_mt-40 {
        max-width: 980px;
    }

    .max_1160_with_mt60 {
        max-width: 980px;
    }

    .max_1160_with_mt48 {
        max-width: 980px;
    }

}


@media only screen and (min-width: 200px) and (max-width: 1023px) {

    .w_10per {
        width: 10%;
    }

    .w_30per {
        width: 30%;
    }

    .w_45per {
        width: 45%;
    }

    .w_50per {
        width: 50%;
    }

    .w_70per {
        width: 70%;
    }

    .gap10 {
        gap: 10px;
    }

    .p16 {
        padding: 16px;
    }

    .pt47_pb20_pl16 {
        padding: 47px 0 20px 16px;
    }

    .pt10 {
        padding-top: 10px;
    }

    .pt0_rbl32 {
        padding: 20px 16px;
    }

    .pb8 {
        padding-bottom: 8px;
    }

    .m20 {
        margin: 20px 16px;
    }

    .mt-45_bor_radi {
        border: 1px solid #ebebeb;
        border-radius: 10px;
        margin: -45px 16px 30px;
        position: relative;
        z-index: 1;
        background: #fff;
    }

    .mt20 {
        margin-top: 20px;
    }

    .ml16 {
        margin-left: 16px;
    }

    .ptb0_plr16 {
        padding: 0px 16px;
    }

    .ptb24_plr16 {
        padding: 24px 16px;
    }

    .pt0_pb20_plr35 {
        padding: 20px;
        border: 1px solid #ebebeb;
        background: #ebebeb;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .pt0_pb40_plr35 {
        padding: 0 35px 40px;
    }

    .pt0_pb18_plr16 {
        padding: 0 18px 16px;
    }

    .mobile_banner_skeleton {
        background: #eee;
        height: 290px;
    }

    .mobile_banner_title_skeleton {
        padding: 30px 16px;
    }

    .mobile_banner_img_skeleton {
        margin: 0 16px;
    }

    .mobile_banner_dot_skeleton {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin: 10px;
    }

    .recent_search_skeleton {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
        /* padding-left: 16px; */
    }

    .why_choseusSkeleton {
        margin-top: 10px;
        margin-bottom: 24px;
        margin-left: 16px;
    }



}


/*----------Madan home page skeleton end-------*/